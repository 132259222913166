<template>
  <div class="footer-wrapper">
    <div class="footer-con">
      <div class="left-con">
        <div class="contact">
          <div class="twitter">
            <img src="../assets/images/twitter.png" />
            <span class="title">Twitter</span>
            <a
              class="name"
              href="https://twitter.com/fastclip1"
              target="_blank"
            >
              @fastclip1</a
            >
          </div>
          <div class="records">
            <span>{{ yearStr }} © FastClip.app</span>
          </div>
        </div>
        <div class="qr-code">
          <img src="../assets/images/qrcode.png" />
        </div>
      </div>
      <div class="right-con">
        <div class="feedback">
          <input type="text" v-model="message" placeholder="Hi, FastClip!" />
          <button type="button" @click.stop="submitMessage" class="">
            Feedback
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
// import { useRouter, useRoute } from "vue-router";
export default {
  name: "Footer",
  // 执行 setup 时，只能访问emit,slots,attrs,props等4个property，不可访问refs(模板 ref),methods,computed,data
  // ES6 写法 { attrs, slots, emit, expose } = context
  setup(props, context) {
    const submitMessage = (val) => {
      let mailHref = `mailto:tigris.shin@gmail.com?subject=Feedback for FastClip&body=${message.value}`;
      let win = window.open(mailHref, "_blank", "location=no");
      setTimeout(() => {
        if (!win.closed) {
          win.close();
        }
      }, 1000);
    };
    // 在方法里访问ref数据，要使用数据名.value, 查看submitMessage方法
    let message = ref("");
    // 在方法里访问reactive数据，就不需要，可以直接访问, 在方法里可以直接用data.yearStr
    let data = reactive({
      yearStr: new Date().getFullYear(),
    });
    return {
      submitMessage,
      message,
      ...toRefs(data),
      yearStr: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
.footer-wrapper {
  background-color: var(--c-white);
  border-top: 0.5px solid var(--c-black-filter3);
  width: 100%;
  position: absolute;
  bottom: 0;
  clear: both;
  height: 110px;
  .footer-con {
    height: 100%;
    width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .left-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      .contact {
        display: flex;
        height: 66px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-right: 32px;
        .twitter {
          display: flex;
          flex-direction: row;
          img {
            width: 23px;
            height: 18.56px;
          }
          span {
            // font-family: HelveticaNeue;
            font-size: 16px;
          }
          .title {
            color: var(--c-black);
            padding-left: 10px;
          }
          .name {
            color: var(--c-blue);
            padding-left: 4px;
            cursor: pointer;
          }
        }
        .records span {
          // font-family: HelveticaNeue;
          font-size: 13px;
          color: var(--c-black);
        }
      }
      .qr-code,
      .qr-code img {
        width: 80px;
        height: 80px;
      }
    }
    .right-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      .feedback {
        display: flex;
        height: 80px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        input {
          width: 253px;
          height: 35px;
          line-height: 35px;
          padding: 0 10px;
          background: var(--c-black-filter5);
          border: 1px solid var(--c-black-filter3);
          box-shadow: 0 2px 4px 0 var(--c-black-filter5);
          border-radius: 10px;
          color: var(--c-black-filter);
        }
        button {
          margin-left: 12px;
          // font-family: HelveticaNeue-Bold;
          font-weight: 600;
          font-size: 16px;
          color: var(--c-white);
          text-align: center;
          border: 1px solid var(--c-black-filter3);
          box-shadow: 0 2px 4px 0 var(--c-black-filter5);
          border-radius: 10px;
          background-color: var(--c-red-link);
          width: 99px;
          height: 35px;
          line-height: 33px;
        }
      }
    }
  }
}


@media screen and (min-width:768px) and (max-width:900px){
  .footer-wrapper {
    .footer-con {
      width: 100%;
      padding: 0 10px;
      justify-content: space-around;
    }
  }
}

@media (max-width: 768px) {
  .footer-wrapper {
    height: 220px;
    .footer-con {
      width: 100%;
      flex-flow: column-reverse;
      justify-content: space-around;
      .right-con {
        .feedback{
          align-items: center;
          input{
            width: 100%;
          }
          button{
            width: 140px;
          }
        }
      }
    }
  }
}
</style>
