<template>
  <div class="home-wrapper">
    <Header />
    <div class="home-content">
      <div class="banner-box">
        <div class="banner-bg">
          <div class="bg bg-1"></div>
          <div class="bg bg-2"></div>
          <div class="bg bg-3"></div>
          <div class="bg bg-4"></div>
          <div class="bg bg-5"></div>        
        </div>
        <div class="title">
          <span>{{ t(`content.title`) }}</span>
        </div>
        <div class="desc">
          {{ t(`content.desc`) }}
        </div>
      </div>
      <div class="content-warpper resource-box">
        <div class="resource-con">
          <div class="con-box">
            <div class="content">
              <div class="con-icon">
                <!-- <img src="../../assets/images/apple.png" /> -->
              </div>
              <div class="con-title">{{ t(`content.appStore`) }}</div>
              <div class="con-other">
                <template v-for="(item, index) in scoreStar" :key="index">
                  <div class="star">
                    <img src="../../assets/images/star.png" />
                  </div>
                </template>
                <span class="score">{{ t(`content.score`) }}</span>
              </div>
            </div>
            <a
              class="open-link"
              target="_blank"
              href="https://apps.apple.com/cn/app/fastclip-collection-manager/id1476085650?l=en"
              >{{ t(`content.openLink`) }}</a
            >
          </div>
        </div>
        <div class="splite-line"></div>
        <div class="chat-con">
          <div class="con-box">
            <div class="content">
              <div class="con-icon">
                <!-- <img src="../../assets/images/t_logo.png" /> -->
              </div>
              <div class="con-title">{{ t(`content.group`) }}</div>
              <div class="con-other">
                <span class="score">{{ t(`content.members`) }}</span>
              </div>
            </div>
            <a
              class="open-link"
              target="_blank"
              href="https://t.me/fastclipchat"
              >{{ t(`content.openLink`) }}</a
            >
          </div>
        </div>
        <div class="splite-line"></div>
        <i18n-t keypath="content.appDesc.name" tag="div" class="app-desc">
          <template #clipboard>
            <span class="bold">{{t(`content.appDesc.clipboard`)}}</span>
          </template>
          <template #keyboard>
            <span class="bold">{{t(`content.appDesc.keyboard`)}}</span>
          </template>
          <template #pro>
            <span class="pro">{{t(`content.appDesc.pro`)}}</span>
          </template>
          <template #share>
            <span class="bold">{{t(`content.appDesc.share`)}}</span>
          </template>
          <template #safari>
            <span class="bold">{{t(`content.appDesc.safari`)}}</span>
          </template>
        </i18n-t>
        <!-- eslint-enable -->
        <div class="splite-line"></div>
        <i18n-t keypath="content.appNote.name" tag="div" class="app-note">
          <template #icloud>
            <span class="bold">{{t(`content.appNote.icloud`)}}</span>
          </template>
          <template #pro>
            <span class="pro">{{t(`content.appNote.pro`)}}</span>
          </template>
          <template #network>
            <span class="bold">{{t(`content.appNote.network`)}}</span>
          </template>
        </i18n-t>
        <!-- eslint-enable -->
      </div>
      <div class="pricing-warpper">
        <div class="content-warpper pricing-box">
          <div class="title">{{ t(`pricing.title`) }}</div>
          <div class="pricing-option">
            <div class="pricing-item" v-for="(item,index) in tm(`pricing.options`) " :key="index">
              <div class="package">{{ item.package }}</div>
              <div class="price">{{ item.price }}</div>
            </div>
          </div>
          <div class="feature-detail">
            <div class="title">{{ t(`features.title`) }}</div>
            <div class="details">
              <div v-for="(item,index) in tm(`features.details`) " :key="index" class="row" :class="[item.type, item.border]">
                <div>{{item.item}}</div>
                <div :value="item.free">
                  <span :class="item.type=='image'?'bg':''">{{item.type!=="image"?item.free:""}}</span>
                </div>
                <div :value="item.pro">
                  <span :class="item.type=='image'?'bg':''">{{item.type!=="image"?item.pro:""}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {useI18n} from "vue-i18n";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  setup(props, context) {
    const { t, tm } = useI18n();
    const state = reactive({
      scoreStar: Number(t(`content.scoreStar`)),
    });

    return {
      t,
      tm,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
html[lang='ar']{
  .home-wrapper {
    .home-content{
      .resource-box{
        .chat-con,.resource-con {
          .con-box {
            .content{
              justify-content: flex-end;
              align-items: flex-end;
            }
            .open-link{
              text-align: right;
              padding-right: 40px;
            }
          }
        }
      }
      .app-note,.app-desc {
        text-align: right;
      }
      .pricing-box{
        .pricing-option{
          .pricing-item{
            text-align: right;
          }
        }
      }
    }
  }
}
.home-wrapper {
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding-bottom: 110px;
  padding-top: 57px;
  position: relative;
  .home-content {
    background: var(--c-bg);
    width: 100%;
    height: 100%;
    min-height: 100%;
    .banner-box {
      width: 835px;
      display: inline-block;
      margin: 0 auto;
      padding: 0px 0 20px 0;
      .banner-bg{
        width: 100%;
        height: 145px;
        position: relative;
        .bg-1 {
          left: 0px;
          bottom: -36px;
          height: 30px;
          width: 30px;
          background: url("../../assets/images/bg1.png") no-repeat center 0;
        }
        .bg-2 {
          left: 160px;
          top: 67px;
          height: 37px;
          width: 36px;
          background: url("../../assets/images/bg2.png") no-repeat center 0;
        }
        .bg-3 {
          left: 364px;
          top: 44px;
          height: 40px;
          width: 40px;
          background: url("../../assets/images/bg3.png") no-repeat center 0;
        }
        .bg-4 {
          right: 184px;
          top: 71px;
          height: 31px;
          width: 31px;
          background: url("../../assets/images/bg4.png") no-repeat center 0;
        }
        .bg-5 {
          right: 10px;
          top: 147px;
          height: 38px;
          width: 39px;
          background: url("../../assets/images/bg5.png") no-repeat center 0;
        }
        .bg {
          position: absolute;
          background-size: contain;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;    
          -ms-interpolation-mode: nearest-neighbor; 
        }
      }
      .title {
        // font-family: HelveticaNeue-Bold;
        font-size: 48px;
        color: var(--c-black);
        padding: 0 80px 36px 80px;
        font-weight: 600;
        position: relative;
        
      }
      .desc {
        // font-family: HelveticaNeue;
        font-size: 16px;
        padding: 0 80px 36px 80px;
        color: var(--c-black);
      }
    }
    .content-warpper{
      width: 610px;
      margin: 0 auto;
    }
    .resource-box {
      padding-bottom: 35px;
    }
    .pricing-warpper{
      background: var(--c-back);
      width: 100%;
    }
    .pricing-box{
      padding-top: 30px;
      padding-bottom: 95px;
      .title{
        color: var(--c-black);
        font-weight: 600;
        font-size: 48px;
        padding-bottom: 35px;
      }
      .pricing-option{
        display: flex;
        flex-direction: row;
        justify-items: center;
        justify-content: space-between;
        .pricing-item{
          background: var(--c-white);
          border-radius: 10px;
          display: flex;
          width: 30%;
          min-width: 100px;
          text-align: left;
          padding: 10px 20px;
          flex-direction: column;
          .package{color: var(--c-black-filter);font-size: 16px;padding-bottom: 10px;}
          .price{color: var(--c-black); font-weight: 700; font-size: 18px; padding-bottom: 5px;}
        }
      }
      .feature-detail{
        .title{
          color: var(--c-black);
          font-weight: 700;
          font-size: 24px;
          padding-top: 42px;
          padding-bottom: 20px;
        }
        .details{
          display: flex;
          flex-direction: column;
          width: 100%;
          .bottom{
            border-bottom: 1px solid var(--c-black-filter4);
          }
          .row{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            justify-items: center;
            padding: 10px 0;
            color: var(--c-black);
            font-size: 16px;
            font-weight: 400;
            div:nth-child(1){
              width: 60%;
              text-align: left;
            }
            div:nth-child(2){
              width: 20%;
              text-align: center;
              justify-content: center;
              display: flex;
            }
            div:nth-child(3){
              width: 20%;
              text-align: center;
              justify-content: center;
              display: flex;
            }
          }
          .header {
            font-size: 16px;
            font-weight: 700;
            div:nth-child(1){
              color: var(--c-black);
            }
            div:nth-child(2){
              color: var(--c-black-filter);
            }
            div:nth-child(3){
              color: var(--c-pro);
            }
          }
          .image{
            div:nth-child(2)[value='0'] .bg,div:nth-child(3)[value='0'] .bg{
              background: url("../../assets/images/check_no.png") no-repeat center center;
              width: 27px;
              height: 28px;
              background-size: contain;
              image-rendering: -moz-crisp-edges; /* Firefox */
              image-rendering: -o-crisp-edges; /* Opera */
              image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
              image-rendering: crisp-edges;    
              -ms-interpolation-mode: nearest-neighbor; 
            }
            div:nth-child(2)[value='1'] .bg,div:nth-child(3)[value='1'] .bg{
              background: url("../../assets/images/check_yes.png") no-repeat center center;
              width: 28px;
              height: 28px;
              background-size: contain;
              image-rendering: -moz-crisp-edges; /* Firefox */
              image-rendering: -o-crisp-edges; /* Opera */
              image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
              image-rendering: crisp-edges;    
              -ms-interpolation-mode: nearest-neighbor; 
            }
          }
        }
      }
    }
    .splite-line {
      background-color: var(--c-black-filter3);
      width: 100%;
      height: 1px;
    }
    .app-note,
    .app-desc {
      padding: 20px 0px;
      text-align: left;
      // font-family: "Helvetica Neue";
      font-size: 16px;
      color: var(--c-black);
      span {
        // font-family: HelveticaNeue-Bold;
        font-weight: 600;
      }
      span.pro {
        color: var(--c-pro);
      }
    }
    .resource-con{
      .con-icon {
        background: url(../../assets/images/apple.png) no-repeat center center;
      }
    }
    .chat-con{
      .con-icon {
        background: url(../../assets/images/t_logo.png) no-repeat center center;
      }
    }
    .resource-con,
    .chat-con {
      width: 100%;
      padding: 22px 0px;
      .con-box {
        background: var(--c-homebg);
        border: 1px solid var(--c-black-filter3);
        box-shadow: 0 2px 4px 0 var(--c-black-filter2);
        border-radius: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        .score{
          color: var(--c-black);
        }
        .content {
          width: 100%;
          padding: 12px 22px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          .con-icon{
            height: 30px;
            width: 30px;
            margin-bottom: 16px;
            background-size: contain;
          }
          .con-title {
            // font-family: HelveticaNeue-Bold;
            font-weight: 600;
            font-size: 16px;
            color: var(--c-black);
            margin-bottom: 11px;
          }
          .con-other {
            display: flex;
            flex-direction: row;
            .star {
              width: 16px;
              height: 16px;
              margin-right: 8px;
              object-fit: contain;
              img {
                width: 16px;
                height: 16px;
              }
              span {
                // font-family: HelveticaNeue;
                font-size: 14px;
                color: var(--c-black-filter);
              }
            }
          }
        }
        .open-link {
          width: 100%;
          line-height: 44px;
          text-align: left;
          padding: 0 22px;
          // font-family: HelveticaNeue;
          font-size: 14px;
          color: var(--c-red-link);
          height: 45px;
          position: relative;
          border-top: 0.5px solid var(--c-black-filter3);
        }
        .open-link:after {
          content: "";
          background: url(../../assets/images/right-arrow.png) no-repeat center
            center;
          width: 20px;
          height: 20px;
          position: absolute;
          background-size: contain;
          right: 5px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
@media screen and (min-width:768px) and (max-width:900px){
  .home-wrapper {
    // padding-bottom: 220px;
    .home-content {
      .banner-box {
        width: 100%;
        // padding: 0 15px;
        .banner-bg{
          .bg-1{
            left: 30px;
          }
          .bg-5{
            right: 30px;
          }
        }
      }
    }
  } 
}
@media (max-width: 768px) {
  .home-wrapper {
    padding-bottom: 220px;
    .home-content {
      .banner-box {
        width: 100%;
        padding: 0 20px;
        .banner-bg{
          .bg-1{
            left: 15px;
            display: none;
          }
          .bg-3{
            left: 46%;
          }
          .bg-2{
            left: 15%;
          }
          .bg-4{
            left: 78%;
          }
          .bg-5{
            display: none;
          }
        }
        .title{
          font-size: 34px;
          padding: 0 0px 36px 0px;
        }
        .desc{
          font-size: 14px;
          padding: 0 0px 36px 0px;
        }
      }
      .resource-box{
        padding: 0 20px;
      }
      .content-warpper{
        width: 100%;
      }
      .pricing-warpper{
        padding: 0 20px;
        .pricing-box{
          .title{
            font-size: 34px;
          }
          .pricing-option{
            .pricing-item{
              width: 30%;
              padding: 10px;
            }
          }
          .feature-detail{
            .details{
              .header,.row{
                font-size: 16px;
              }
            }
            .title{
              font-size: 20px;
            }
          }
        }
      }
    }
    
  }
}
@media screen and (min-width:414px) and (max-width:680px){
  .home-wrapper {
    padding-bottom: 220px;
    .home-content {
      .pricing-warpper{
        padding: 0 20px;
        .pricing-box{
          .pricing-option{
            .pricing-item{
              width: 30%;
              padding: 10px;
            }
          }
        }
      }
    }
  }
  html[lang='ar']{
    .home-wrapper {
      .home-content{
        .banner-box{
          .title{
            text-align: right;
          }
          .desc{
            text-align: right;
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  html[lang='ar']{
    .home-wrapper {
      .home-content{
        .banner-box{
          .title{
            text-align: right;
          }
          .desc{
            text-align: right;
          }
        }
      }
    }
  }
  .home-wrapper {
    padding-bottom: 220px;
    .home-content {
      .pricing-warpper{
        padding: 0 20px;
        .pricing-box{
          .pricing-option{
            .pricing-item{
              width: 30%;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  .home-wrapper {
    .home-content {
      .resource-box{
        .resource-con {
          .con-box{
            .content{
              .con-icon {
                background: url(../../assets/images/apple-dark.png) no-repeat center center;
                background-size: contain;
              }
            }
          }
        }
      }
      // .pricing-box{
      //   .feature-detail{
      //     .details{
      //       .image{
      //         div:nth-child(2)[value='0'] .bg,div:nth-child(3)[value='0'] .bg{
      //           background: url("../../assets/images/check_no_dark.png") no-repeat center center;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
