<template>
  <div id="header-nav" class="header-wrapper">
    <div class="header-content" ref="menu">
      <div class="fastclip">
        <img src="../assets/images/fastclip.png" />
        <!-- <img src="https://imgs.zhubai.love/e6afffc7730c4eec9273bd1b903f970a.png"/> -->
        <span>FastClip</span>
      </div>
      <a href="#" class="custom-toggle" @click.stop="toggleMenu" id="toggle">
        <s class="bar"></s><s class="bar"></s><s class="bar"></s>
      </a>
      <div class="right-con custom-can-transform pure-menu-horizontal">
        <a class="story-item" href="https://fastclip.zhubai.love/" target="_blank">
          <span>{{ t(`header.story`) }}</span>
        </a>
        <a class="kit-item"  href="https://fastclip.app/presskit/PressKit.zip" target="_blank">
          <span>{{ t(`header.kit`) }}</span>
        </a>
        <div class="lan-dropdown-item">
          <div class="lan" @click.stop="bodyTouchStart">{{ t(`header.language`) }}</div>
          <ul class="lan-dialog" v-show="showLan">
            <li v-for="item in tm(`languages`) " @click.stop="changeLanguage(item.lan)" :key="item.lan" :class="t(`key`) == item.lan?'lan-item active':'lan-item'">{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "Header",
  setup(props, context) {
    // t:获取字符串 tm获取数组
    const { t, tm, locale } = useI18n();
    let currentInstance = null
    const state = reactive({
      locale: locale,
      showLan: false
    });

    /**
     * lang: local
     */
    const changeLanguage = (lang) => {
      state.locale = lang
      document.querySelector('html').setAttribute('lang', lang);
    };  

    const bodyTouchStart = (e) => {
      state.showLan = true
      // e.preventDefault()
    }

    const toggleHorizontal = () => {
      [].forEach.call(
        currentInstance.refs.menu.querySelectorAll('.custom-can-transform'),
        (el) => {
          // window.localStorage.setItem("mobile","hideMenu");
          el.classList.toggle('pure-menu-horizontal')
        }
      )
    };
    const toggleMenu = () => {
      var menu = currentInstance.refs.menu
      if (menu.classList.contains('open')) {
        setTimeout(toggleHorizontal, 500)
      } else {
        toggleHorizontal()
      }
      menu.classList.toggle('open')
      document.getElementById('toggle').classList.toggle('x')
    };
    const closeMenu = () => {
      let menu = currentInstance.refs.menu
      state.showLan = false
      if (menu) {
        if (menu.classList.contains('open')) {
          toggleMenu()
        }
      }
    }

    onMounted(() => {
      currentInstance = getCurrentInstance() // 有效
      document.querySelector('html').setAttribute('lang', state.locale);
      currentInstance.appContext.config.globalProperties.globalClick(()=>{closeMenu()})
      console.log("currentInstance.appContext.config.globalProperties.testApp=",currentInstance.appContext.config.globalProperties.testApp);
    })

    return {
      changeLanguage,
      bodyTouchStart,
      t,
      tm,
      ...toRefs(state),
      toggleMenu,
      closeMenu
    };
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
.header-wrapper {
  width: 100%;
  height: 57px;
  background-color: var(--c-back);
  border-bottom: 1px solid var(--c-header-bottom);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  .header-content.open{
    height: auto;
  }
  .header-content {
    width: 900px;
    height: 100%;
    margin: 0 auto;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    transition: height 0.5s;
    .fastclip {
      display: flex;
      align-items: center;
      object-fit: contain;
      height: 57px;
      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        // border-radius: 11px;
      }
      span {
        padding-left: 14px;
        // font-family: HelveticaNeue-Bold;
        font-weight: 600;
        font-size: 20px;
        color: var(--c-black);
      }
    }
    .right-con {
      display: flex;
      align-items: center;
      .story-item{
        cursor: pointer;
        padding: 10px 0 10px 38px;
      }
      .kit-item{
        padding: 10px 0 10px 38px;
      }
      .kit-item span:hover, .story-item span:hover{
        color: var(--c-red);
      }
      .story-item span, .kit-item span, .lan {
        // font-family: HelveticaNeue-Bold;
        font-weight: 600;
        font-size: 16px;
        color: var(--c-black);
      }
      .lan-dropdown-item{
        padding-left: 38px;
        cursor: pointer;
        position: relative;
        .lan{
          padding: 10px 0;
        }
        .lan-dialog{
          display: none;
          margin: 0;
          padding: 7px 12px 24px 18px;
          list-style: none;
          position: absolute;
          top: 35px;
          right: -38px;
          border-radius: 6px;
          min-width: 148px;
          background-color: var(--c-bg);
          box-shadow: var(--shadow-3);
          .lan-item{
            text-align: left;
            padding: 5px 0;
            margin-top: 5px;
            border-bottom: 1px var(--c-line) solid;
            // font-family: HelveticaNeue;
            font-size: 16px;
            color: var(--c-black);
          }
          .lan-item:hover,.lan-item.active{
            text-decoration: none;
            color: var(--c-red)
          }
        }
      }
      .lan-dropdown-item:hover .lan-dialog{
        display: block;
        z-index: 100;
      }
      
    }
    .custom-toggle {
      width: 40px;
      height: 57px;
      position: absolute;
      top: 0;
      right: 10px;
      display: none;
      z-index: 9;
    }
    .custom-toggle .bar {
      background-color: var(--c-black);
      display: block;
      width: 20px;
      height: 2px;
      border-radius: 100px;
      position: absolute;
      top: 28px;
      right: 10px;
      -webkit-transition: all 0s;
      -moz-transition: all 0s;
      -ms-transition: all 0s;
      transition: all 0s;
    }
    .custom-toggle .bar:first-child{
      -webkit-transform: translateY(-6px);
      -moz-transform: translateY(-6px);
      -ms-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    .custom-toggle .bar:nth-child(2) {
      -webkit-transform: translateY(6px);
      -moz-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
    }
    // .custom-toggle .bar:nth-child(3) {
    //   -webkit-transform: translateY(12px);
    //   -moz-transform: translateY(12px);
    //   -ms-transform: translateY(12px);
    //   transform: translateY(12px);
    // }
    .custom-toggle.x .bar {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .custom-toggle.x .bar:first-child,.custom-toggle.x .bar:nth-child(2)  {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}
.pure-menu-horizontal {
  // width: 100%;
  white-space: nowrap;
}

@media screen and (min-width:768px) and (max-width:900px){
  .header-wrapper {
    .header-content {
      width: 100%;
      padding: 0 30px;
      .right-con {
        .lan-dropdown-item{
          .lan-dialog{
            right: -20px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header-wrapper {
    width: 100%;
    .header-content.open{
      overflow: unset;
    }
    .header-content {
      width: 100%;
      overflow: hidden;
      display: block;
      .custom-toggle {
        display: block;
      }
      .fastclip {
        width: 100%;
        padding: 0 20px;
      }
      .right-con {
        background: var(--c-back);
        width: 100%;
        flex-direction: column;
        justify-items: flex-start;
        width: 160px;
        float: right;
        flex-direction: column;
        justify-items: left;
        text-align: start;
        .lan-dropdown-item,.kit-item,.story-item{
          width: 100%;
          .lan-dialog{
            right: 100%;
            top: 0%;
          }
        }
      }
    }
  }
}
</style>
